@font-face {
    font-display: swap;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 400;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/Arial.woff') format('woff2');
}

@font-face {
    font-display: swap;
    font-family: 'Arial';
    font-style: normal;
    font-weight: 700;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/ArialBold.woff') format('woff2');
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 300;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-300.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 400;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-regular.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 500;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-500.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 600;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-600.woff2') format("woff2");
}
@font-face {
    font-family: "NotoSansArmenian";
    font-weight: 700;
    font-display: swap;
    src: url('https://d35v9chtr4gec.cloudfront.net/fonts/tenants/noto-sans-armenian-v39-armenian_latin-700.woff2') format("woff2");
}

@import 'home';
@import '../../global/tenants-global';


:root {
    --nav-background: #ffffff;
    --nav-link: #000000;
    --nav-link-hover: #D14D72;

    --btn1-background-color: #D14D72;
    --btn1-background-hover-color: #C94167FF;
    --btn1-text-color: #ffffff;
    --btn1-text-hover-color: #ffffff;

    --btn1-outline-background-color: #ffffff;
    --btn1-outline-background-hover-color: #D14D72;
    --btn1-outline-text-color: #000000;
    --btn1-outline-text-color-hover: #ffffff;
    --btn1-outline-border-color: #D14D72;
    --btn1-outline-border-hover-color: #ffffff;


    --btn2-background-color: #5f5f5f;
    --btn2-background-hover-color: #484848;
    --btn2-text-color: #ffffff;
    --btn2-text-hover-color: #ffffff;

    --btn2-outline-background-color: #ffffff;
    --btn2-outline-background-hover-color: #5f5f5f;
    --btn2-outline-text-color: #000000;
    --btn2-outline-text-color-hover: #ffffff;
    --btn2-outline-border-color: #5f5f5f;
    --btn2-outline-border-hover-color: #5f5f5f;


    --color-1: #D14D72;
    --color-2: #5f5f5f;


    --course-background: #ffffff;
    --course-background-hover: #f8f8f8;
    --course-color: #52677A;
    --course-color-hover: #52677A;
    --course-color-hr: #e4e8f2;
    --course-color-hr-hover: #e4e8f2;
    --course-border: #849DB5;
    --course-radius: 20px;

}

h1, h2, h3, h4, h5, h6, p {
    min-height: 17px;
}


body{
    font-family: Arial, NotoSansArmenian, -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.btn {
    letter-spacing: 0;
}


.th-26-landing-home-section {
    background: #ffffff;
    padding-top: 3.125rem;
    padding-bottom: 6.25rem;

    @media(max-width: 991px) {
        padding-block: 3.125rem;
    }

    @media(max-width: 575px) {
        padding-block: 1.875rem;
    }
}

.th-26-landing-home-section-title {
    text-align: center;
    color: #010101;
    font-size: 40px;
    font-weight: 600;
    margin-bottom: 3.75rem;

    @media(max-width: 991px) {
        margin-bottom: 2.5rem;
    }

    @media(max-width: 575px) {
        font-size: 2rem;
    }
}

.th-26-landing-home-container {
    max-width: 1260px;
    margin-inline: auto;
    padding-inline: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;

    @media(max-width: 991px) {
        flex-direction: column;
    }

    @media(max-width: 575px) {
        gap: 1.875rem;
    }
}

.th-26-landing-home-container-left {
    max-width: 478px;
    width: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
    }
}

.th-26-landing-home-container-right {
    max-width: 609px;
    width: 60%;
    display: flex;
    flex-direction: column;
    padding-right: 1.875rem;

    @media(max-width: 991px) {
        width: 100%;
        text-align: center;
        padding-right: 0;
    }

    .th-26-home-title {
        color: #000;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1.25rem;
    }

    .th-26-home-subtitle {
        color: #000;
        font-size: 1.125rem;
        line-height: 184.615%;
        margin-bottom: 0;
    }
}

.th-26-landing-team-section {
    background: #FFF9FD;
    padding-top: 3.125rem;
    padding-bottom: 6.25rem;

    @media(max-width: 991px) {
        padding-block: 3.125rem;
    }

    @media(max-width: 575px) {
        padding-block: 1.875rem;
    }
}

.th-26-landing-team-container {
    max-width: 1260px;
    margin-inline: auto;
    padding-inline: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;

    @media(max-width: 991px) {
        flex-direction: column-reverse;
    }

    @media(max-width: 575px) {
        gap: 1.875rem;
    }
}

.th-26-landing-team-container-right {
    max-width: 478px;
    width: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
    }
}

.th-26-landing-team-container-left {
    max-width: 609px;
    width: 60%;
    display: flex;
    flex-direction: column;
    padding-right: 1.875rem;

    @media(max-width: 991px) {
        width: 100%;
        text-align: center;
        padding-right: 0;
    }

    .th-26-team-title {
        color: #000;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1.25rem;
    }

    .th-26-team-subtitle {
        color: #000;
        font-size: 1.125rem;
        line-height: 184.615%;
        margin-bottom: 0;
    }
}

.th-26-landing-program-section {
    background: #ffffff;
    padding-block: 6.25rem;

    @media(max-width: 991px) {
        padding-block: 3.125rem;
    }

    @media(max-width: 575px) {
        padding-block: 1.875rem;
    }
}

.th-26-landing-program-container {
    max-width: 1260px;
    margin-inline: auto;
    padding-inline: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;

    @media(max-width: 991px) {
        flex-direction: column;
    }

    @media(max-width: 575px) {
        gap: 1.875rem;
    }
}

.th-26-landing-program-container-left {
    max-width: 478px;
    width: 100%;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 6px;
    }
}

.th-26-landing-program-container-right {
    max-width: 609px;
    width: 60%;
    display: flex;
    flex-direction: column;
    padding-right: 1.875rem;

    @media(max-width: 991px) {
        width: 100%;
        text-align: center;
        padding-right: 0;
    }

    .th-26-program-title {
        color: #000;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1.25rem;
    }

    .th-26-program-subtitle {
        color: #000;
        font-size: 1.125rem;
        line-height: 184.615%;
        margin-bottom: 0;
    }

    .th-26-landing-program-button {
        display: flex;

        @media(max-width: 991px) {
            justify-content: center;
        }
    }
}

.th-26-landing-balance-text-box {
    max-width: 609px;
    display: flex;
    flex-direction: column;
    gap: 1.25rem;

    @media(max-width: 991px) {
        text-align: center;
    }

    h2 {
        color: #000;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: 1.25rem;
    }

    p {
        color: #000;
        font-size: 1.125rem;
        line-height: 184.615%;
        margin-bottom: 0;
    }
}

.th-26-landing-register-section {
    position: relative;
    min-height: 313px;
    padding-block: 5.7rem;

    .th-26-landing-register-background {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
    }
}

.th-26-landing-register-container {
    position: relative;
    max-width: 1260px;
    margin-inline: auto;
    padding-inline: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    z-index: 1;

    @media(max-width: 991px) {
        flex-direction: column;
    }

    @media(max-width: 575px) {
        gap: 1.875rem;
    }
}

.th-26-landing-register-title {
    max-width: 660px;
    color: #FFF;
    font-size: 3.125rem;
    font-weight: 700;
    line-height: 130%;

    span {
        color: #D14D72;
    }

    @media(max-width: 991px) {
        text-align: center;
        font-size: 2rem;
    }

    @media(max-width: 575px) {
        font-size: 1.7rem;
    }
}

.th-26-landing-register-button {
    display: flex;

    @media(max-width: 991px) {
        justify-content: center;
    }
}


.th-26-info-background {
    width: 100%;
    min-height: 450px;
    height: 450px;

    img {
        width: 100%;
        min-height: 100%;
        object-fit: cover;
        height: 100%;

    }
}

.th-26-info-second-section {
    background: #fff;
    padding-block: 3.125rem;

    @media(max-width: 575px) {
        padding-block: 1.875rem;
    }
}

.th-26-info-second-container {
    max-width: 770px;
    margin-inline: auto;
    padding-inline: 1.25rem;
    display: flex;
    flex-direction: column;

    h3 {
        text-align: center;
        color: #000;
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 2.4rem;
    }

    p {
        color: #000;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 160%;
        margin-bottom: 1.25rem;
    }
}

.th-26-info-resources-section {
    padding-block: 3.125rem;

    @media(max-width: 575px) {
        padding-block: 1.875rem;
    }
}

.th-26-info-resources-container {
    max-width: 770px;
    margin-inline: auto;
    padding-inline: 1.25rem;
    display: flex;
    flex-direction: column;

    h2 {
        text-align: center;
        color: #000;
        font-size: 2rem;
        font-weight: 700;
        margin-bottom: 2.4rem;
    }

    p {
        color: #000;
        font-size: 1.25rem;
        font-weight: 400;
        line-height: 160%;
        margin-bottom: 1.25rem;
    }
}

.th-26-info-resources-image {
    max-width: 730px;
    margin-top: 1.7rem;

    img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
}

.th-26-info-resources-button {
    display: flex;
    justify-content: center;
    margin-top: 3.75rem;
}
