.th-26-hero-section {
    position: relative;
    width: 100%;
    min-height: 757px;
    padding: 10.625rem 6.875rem 13.375rem;

    @media(max-width: 991px) {
        min-height: 534px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-inline: 1.25rem;
        padding-bottom: 3.75rem;
        padding-top: 0;
    }

    @media(max-width: 575px) {
        min-height: 300px;
        padding-block: 1.875rem;
    }

    .th-26-hero-background {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
    }
}

.th-26-hero-info {
    position: relative;
    max-width: 600px;
    display: grid;
    z-index: 1;

    @media(max-width: 991px) {
        max-width: 500px;
        background: rgb(255,255,255,.4);
        border-radius: 12px;
        backdrop-filter: blur(5px);
        padding: 1.25rem;
        box-shadow: 1px 4px 15px rgb(0,0,0,0.4);
    }

    .th-26-hero-title {
        color: #010101;
        font-size: 3.875rem;
        font-weight: 700;
        line-height: 107%;
        margin-bottom: 1.875rem;

        span {
            color: #D14D72;
        }

        @media(max-width: 991px) {
            font-size: 3rem;
        }

        @media(max-width: 575px) {
            font-size: 2rem;
        }
    }
}

.th-26-hero-subtitle {
    color: #010101;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 2.5rem;

    @media(max-width: 991px) {
        font-size: 1.25rem;
    }

    @media(max-width: 575px) {
        font-size: 1.125rem;
    }
}

.th-26-hero-button {
    display: flex;
}

.th-26-rating-section {
    position: relative;
    margin-top: -5rem;
    padding-inline: 1.25rem;

    @media(max-width: 767px) {
        margin-top: -2rem;
    }

    @media(max-width: 575px) {
        margin-top: 1.875rem;
    }
}

.th-26-rating-container {
    background: #FFF;
    max-width: 1220px;
    margin-inline: auto;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    gap: 2.5rem;
    padding: 2.5rem 3.75vw;
    box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.15);
    border-radius: 20px;

    @media(max-width: 1199px) {
        padding-inline: 1.25rem;
    }

    @media(max-width: 767px) {
        grid-template-columns: repeat(2,1fr);
        padding-block: 1.5rem;
    }

    @media(max-width: 575px) {
        max-width: 500px;
    }
}

.th-26-rating-item {
    display: flex;
    align-items: center;
    gap: 10px;

    @media(max-width: 1199px) {
        flex-direction: column;
        text-align: center;
    }

    span {
        color: #D14D72;
        font-size: 3.25rem;
        font-weight: 700;
        line-height: 130%;

        @media(max-width: 575px) {
            font-size: 2.8rem
        }
    }

    p {
        max-width: 120px;
        color: #5C5C5C;
        font-size: 1.125rem;
        font-weight: 600;
        line-height: 130%;
        margin-bottom: 0;

        @media(max-width: 1199px) {
            max-width: 100%;
        }

        @media(max-width: 575px) {
            font-size: 1rem
        }
    }
}

.th-26-about-section {
    background: #fff;
    padding-block: 6.25rem;

    @media(max-width: 991px) {
        padding-block: 3.75rem;
    }

    @media(max-width: 575px) {
        padding-block: 1.875rem;
    }
}

.th-26-about-container {
    max-width: 1260px;
    margin-inline: auto;
    padding-inline: 1.25rem;
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 991px) {
        flex-direction: column;
    }

    @media(max-width: 575px) {
        gap: 1.875rem;
    }
}

.th-26-about-left {
    max-width: 478px;
    width: 100%;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
    }
}

.th-26-about-right {
    max-width: 601px;
    width: 100%;
    display: grid;
}

.th-26-about-right-text {
    display: grid;

    @media(max-width: 991px) {
        text-align: center;
        place-items: center;
    }

    @media(max-width: 575px) {
        text-align: left;
        place-items: flex-start;
    }

    h2 {
        color: #010101;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 130%;
        margin-bottom: 1.875rem;

        @media(max-width: 991px) {
            font-size: 2rem;
        }

        @media(max-width: 575px) {
            font-size: 1.7rem;
        }
    }

    p {
        color: #010101;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 130%;
        margin-bottom: .9rem;

        @media(max-width: 575px) {
            font-size: 1.125rem;
        }
    }
}

.th-26-about-right-list {
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    gap: 0 3.75rem;
    margin-top: .9rem;
    margin-bottom: 0;
    padding-left: 0;

    @media(max-width: 991px) {
        justify-content: center;
    }

    @media(max-width: 575px) {
        justify-content: flex-start;
    }

    li{
        max-width: 208px;
        width: 100%;
        display: flex;
        gap: 10px;
        align-items: center;

        img {
            width: 18px;
            height: 18px;
            object-fit: contain;
        }

        span {
            color: #010101;
            font-size: 1.3rem;
            font-weight: 400;
            line-height: 166%;

            @media(max-width: 575px) {
                font-size: 1.125rem;
            }
        }
    }
}

.th-26-about-buttons {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    margin-top: 3.125rem;

    @media(max-width: 991px) {
        justify-content: center;
    }

    @media(max-width: 575px) {
        text-align: left;
        justify-content: flex-start;
    }
}

.th-26-relationship-section {
    background: #fff;
    padding: 3.125rem 0 6.25rem;

    @media(max-width: 991px) {
        padding-block: 3.125rem;
    }

    @media(max-width: 575px) {
        padding-block: 1.875rem;
    }
}

.th-26-relationship-container {
    max-width: 1260px;
    margin-inline: auto;
    padding-inline: 1.25rem;
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 991px) {
        flex-direction: column-reverse;
    }

    @media(max-width: 575px) {
        gap: 1.875rem;
    }
}

.th-26-relationship-left {
    max-width: 614px;
    width: 100%;
}

.th-26-relationship-left-text {
    display: grid;

    @media(max-width: 991px) {
        text-align: center;
        place-items: center;
    }

    @media(max-width: 575px) {
        text-align: left;
        place-items: flex-start;
    }

    h2 {
        color: #010101;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 130%;
        margin-bottom: 1.875rem;

        @media(max-width: 991px) {
            font-size: 2rem;
        }

        @media(max-width: 575px) {
            font-size: 1.7rem;
        }
    }

    p {
        color: #010101;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 130%;
        margin-bottom: .9rem;

        @media(max-width: 575px) {
            font-size: 1.125rem;
        }
    }
}

.th-26-relationship-right {
    max-width: 478px;
    width: 100%;
    display: grid;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
    }
}

.th-26-relationship-button {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    margin-top: 1.5rem;

    @media(max-width: 991px) {
        justify-content: center;
    }

    @media(max-width: 575px) {
        text-align: left;
        justify-content: flex-start;
    }
}

.th-26-register-section {
    position: relative;
    min-height: 313px;
    padding-block: 5.7rem;

    @media(max-width: 991px) {
        padding-block: 3.125rem;
    }

    @media(max-width: 767px) {
        min-height: 270px;
    }

    .th-26-register-background {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
    }
}

.th-26-register-container {
    position: relative;
    max-width: 1260px;
    margin-inline: auto;
    padding-inline: 1.25rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 3rem;
    z-index: 1;

    @media(max-width: 991px) {
        flex-direction: column;
        gap: 2rem;
    }

    @media(max-width: 575px) {
        gap: 1.875rem;
    }
}

.th-26-register-title {
    max-width: 660px;
    color: #FFF;
    font-size: 3.125rem;
    font-weight: 700;
    line-height: 130%;

    span {
        color: #D14D72;
    }

    @media(max-width: 991px) {
        text-align: center;
        font-size: 2rem;
    }

    @media(max-width: 575px) {
        font-size: 1.7rem;
    }
}

.th-26-register-button {
    display: flex;

    @media(max-width: 991px) {
        justify-content: center;
    }
}

.th-26-help-section {
    background: #FFF9FD;
    padding-block: 6.25rem;

    @media(max-width: 991px) {
        padding-block: 3.125rem;
    }

    @media(max-width: 575px) {
        margin-top: 1.875rem;
        padding-block: 1.875rem;
    }
}

.th-26-help-container {
    max-width: 1260px;
    margin-inline: auto;
    padding-inline: 1.25rem;
    display: grid;
    gap: 3.75rem;
}

.th-26-help-title {
    text-align: center;
    color: #010101;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 120%;
    margin: 0;

    @media(max-width: 991px) {
        font-size: 2rem;
    }

    @media(max-width: 575px) {
        font-size: 1.7rem;
    }
}

.th-26-help-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3.125rem 2.5rem;

    @media(max-width: 1199px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media(max-width: 575px) {
        grid-template-columns: 1fr;
        gap: 1.875rem;
    }
}

.th-26-help-item {
    background: #FFF;
    display: grid;
    place-content: flex-start;
    gap: 2.25rem;
    border-radius: 20px;
    padding: 1.625rem 1.875rem;

    figure {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 1.875rem;

        img {
            width: 52px;
            height: 60px;
            object-fit: contain;
        }

        figcaption {
            color: #010101;
            font-size: 1.875rem;
            font-weight: 700;
            line-height: 106.667%;
        }
    }

    p {
        color: #414141;
        font-size: 1rem;
        font-weight: 400;
        line-height: 156.25%;
        margin: 0;
    }
}

.th-26-get-started-section {
    background: #fff;
    padding: 3.125rem 0 6.25rem;

    @media(max-width: 991px) {
        padding-block: 3.125rem;
    }

    @media(max-width: 575px) {
        padding-block: 1.875rem;
    }
}

.th-26-get-started-container {
    max-width: 1260px;
    margin-inline: auto;
    padding-inline: 1.25rem;
    display: flex;
    gap: 3rem;
    align-items: center;
    justify-content: space-between;

    @media(max-width: 991px) {
        flex-direction: column-reverse;
    }

    @media(max-width: 575px) {
        gap: 1.875rem;
    }
}

.th-26-get-started-left {
    max-width: 478px;
    width: 100%;

    img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 20px;
    }
}

.th-26-get-started-right-text {
    display: grid;

    @media(max-width: 991px) {
        text-align: center;
        place-items: center;
    }

    @media(max-width: 575px) {
        text-align: left;
        place-items: flex-start;
    }

    h2 {
        color: #010101;
        font-size: 2.5rem;
        font-weight: 600;
        line-height: 130%;
        margin-bottom: 1.875rem;

        @media(max-width: 991px) {
            font-size: 2rem;
        }

        @media(max-width: 575px) {
            font-size: 1.7rem;
        }
    }

    p {
        color: #010101;
        font-size: 1.3rem;
        font-weight: 400;
        line-height: 130%;
        margin-bottom: .9rem;

        @media(max-width: 575px) {
            font-size: 1.125rem;
        }
    }
}

.th-26-get-started-right {
    max-width: 614px;
    width: 100%;
    display: grid;
}

.th-26-get-started-button {
    display: flex;
    flex-wrap: wrap;
    gap: 1.25rem;
    margin-top: 1.5rem;

    @media(max-width: 991px) {
        justify-content: center;
    }

    @media(max-width: 575px) {
        text-align: left;
        justify-content: flex-start;
    }
}

.th-26-clients-section {
    background: #FFF9FD;
    padding-block: 6.25rem;

    @media(max-width: 991px) {
        padding-block: 3.125rem;
    }

    @media(max-width: 575px) {
        margin-top: 1.875rem;
        padding-block: 1.875rem;
    }
}

.th-26-clients-container {
    max-width: 1260px;
    margin-inline: auto;
    padding-inline: 1.25rem;
    display: grid;
    gap: 5rem;

    @media(max-width: 991px) {
        gap: 3.125rem;
    }
}

.th-26-clients-title {
    text-align: center;
    color: #010101;
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 120%;
    margin: 0;

    @media(max-width: 991px) {
        font-size: 2rem;
    }

    @media(max-width: 575px) {
        font-size: 1.7rem;
    }
}

.th-26-clients-wrapper {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3.125rem 2.5rem;

    @media(max-width: 1199px) {
        grid-template-columns: repeat(2, 1fr);
    }

    @media(max-width: 575px) {
        grid-template-columns: 1fr;
        gap: 1.875rem;
    }
}

.th-26-clients-item {
    background: #FFF;
    display: grid;
    place-content: flex-start;
    gap: 2.25rem;
    border-radius: 20px;
    padding: 1.625rem 1.875rem;

    figure {
        margin: 0;
        display: flex;
        align-items: center;
        gap: 6px;

        img {
            width: 56px;
            height: 56px;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            object-fit: contain;
        }

        figcaption {

            h4 {
                color: #010101;
                font-size: 13px;
                font-weight: 700;
                line-height: 150%;
                margin-bottom: 1px;
            }

            span {
                color: #000;
                font-size: 13px;
                font-weight: 200;
                line-height: 150%;
            }
        }
    }

    p {
        color: #414141;
        font-size: 1rem;
        font-weight: 400;
        line-height: 168.75%;
        margin: 0;
    }
}

.th-26-clients-item-wrapper {
    display: flex;
    justify-content: space-between;

    .th-26-clients-quot {
        width: 22px;
        height: 30px;
        object-fit: contain
    }
}

.th-26-faq-section {
    background: #fff;
    padding-block: 6.25rem;

    @media (max-width: 991px) {
        padding-block: 3.75rem;
    }
    @media (max-width: 575px) {
        padding-block: 1.875rem;
    }
}

.th-26-faq-container {
    max-width: 1060px;
    margin-inline: auto;
    padding-inline: 1.25rem;
    display: flex;
    flex-direction: column;

    .th-26-faq-title {
        position: relative;
        width: 100%;
        text-align: center;
        color: #18181B;
        font-size: 2.5rem;
        font-weight: 700;
        line-height: 150%;
        margin: 0 0 4.375rem;

        @media(max-width: 1199px) {
            font-size: 2.5rem;
        }

        @media (max-width: 991px) {
            font-size: 2rem;
            margin-bottom: 3.125rem;
        }

        @media (max-width: 575px) {
            text-align: left;
            font-size: 1.7rem;
        }
    }
}

.th-26-faq-container-wrapper {
    width: 100%;
    display: grid;
    gap: 1.6rem;
}

.th-26-faq-item {
    display: flex;
    flex-direction: column;
    gap: 1.875rem;
    border-bottom: 1px solid #FFC1E6;
    padding-right: 3rem;

    h3 {
        color: #000;
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 141.667%;
        margin: 0;
    }

    p {
        color: #52525B;
        font-size: 1rem;
        line-height: 175%;
        margin-bottom: 1.875rem;
    }
}
